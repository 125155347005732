<template>
    <v-row>
        <BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

        <v-col cols="12" class="d-none d-md-block">
            <ToolbarContainer :menu="menu" />
            <v-toolbar elevation="1" height="25" rounded="b">
                <Breadcrumbs />
            </v-toolbar>
        </v-col>

        <v-col cols="12" lg="12">
            <router-view />
        </v-col>
    </v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
    name: "ProjetoContainer",
    components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
    data: () => ({
        menu: [],
    }),
    methods: {
        montarMenu() {
            let m = [];

            // if (this.permissao("projeto")) {
            //     m.push({
            //         titulo: "Projetos",
            //         titulo_app: "Projetos",
            //         icon: "mdi-message-question-outline",
            //         to: "/projeto",
            //         tooltip: "Lista de Projetos",
            //     });
            // }

            // if (this.permissao("projeto")) {
            //     m.push({
            //         titulo: "Novo Projeto",
            //         titulo_app: "Novo",
            //         icon: "mdi-message-plus-outline",
            //         to: "/projeto/novo",
            //         tooltip: "Tela de agendamento de tarefas",
            //     });
            // }

            if (this.permissao("projeto")) {
                m.push({
                    titulo: "Registros de demandas",
                    titulo_app: "Registros",
                    icon: "mdi-bell-badge-outline",
                    to: "/projeto",
                    tooltip: "Lista de tarefas",
                });
            }

            // if (this.permissao("projeto")) {
            // 	m.push({
            // 		titulo: "Notificações",
            // 		titulo_app: "Notificações",
            // 		icon: "mdi-bell-badge-outline",
            // 		to: "/projeto/notificacao",
            // 		tooltip: "Lista de notificações de tarefas",
            // 	});
            // }



            this.menu = m;
        },
    },
    mounted() {
        this.montarMenu();
    },
};
</script>

<style scoped>
</style>
